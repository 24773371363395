// Reset
@import '../node_modules/normalize.css/normalize.css';

$font-base-path: '../../../../assets/fonts';

//// Basics
// Colors
@import 'frok/src/frontend-kit/basics/colors/colors.scss';
@import 'frok/src/frontend-kit/basics/colors/theme.mixin.scss';
@import 'frok/src/frontend-kit/basics/colors/theme.scss';

// Typography
@import 'frok/src/frontend-kit/basics/fonts.scss';
@import 'frok/src/frontend-kit/basics/basics.scss';
@import 'frok/src/frontend-kit/basics/bosch-icon.scss';
@import 'frok/src/frontend-kit/basics/layout/layout.scss';
@import 'frok/src/frontend-kit/basics/typography/typography.scss';

// Viewports & Grids
@import 'frok/src/frontend-kit/basics/viewports.scss';
@import '../src/base/grids.scss';

// FROK Atoms
@import 'frok/src/frontend-kit/atoms/button/button.scss';
@import 'frok/src/frontend-kit/atoms/checkbox/checkbox.scss';
@import 'frok/src/frontend-kit/atoms/divider/divider.scss';
@import 'frok/src/frontend-kit/atoms/dropdown/dropdown.scss';
@import 'frok/src/frontend-kit/atoms/icon/icon.scss';
@import 'frok/src/frontend-kit/atoms/image/image.scss';
@import 'frok/src/frontend-kit/atoms/link/link.scss';
@import 'frok/src/frontend-kit/atoms/notification/notification.scss';
@import 'frok/src/frontend-kit/atoms/progressIndicator/progressIndicator.scss';
@import 'frok/src/frontend-kit/atoms/radioButton/radioButton.scss';
@import 'frok/src/frontend-kit/atoms/slider/slider.scss';

// Molecules
@import 'frok/src/frontend-kit/molecules/dialog/dialog.scss';
@import 'frok/src/frontend-kit/molecules/languageSelector/languageSelector.scss';

// FROK resets
// General Resets
h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.a-notification {
  margin-bottom: 2rem;
  width: 100%;
}

.a-button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.a-link {
  text-align: center;
  width: 100%;
}

@include rb-core-tablet {
  .a-link {
    width: fit-content;
  }
}

// BBQ Components
@import './components/Badge/Badge.scss';
@import './components/BonusRound/BonusRoundContent/BonusRoundContent.scss';
@import './components/BonusRound/BonusRoundIntro/BonusRoundIntro.scss';
@import './components/BonusRound/BonusRoundOutro/BonusRoundOutro.scss';
@import './components/BonusRound/BonusRound.scss';
@import './components/ChoiceQuestion/ChoiceQuestion.scss';
@import './components/Dialog/Dialog.scss';
@import './components/Feedback/Feedback.scss';
@import './components/FindingThings/FindingThings.scss';
@import './components/Footer/Footer.scss';
@import './components/Guessing/Guessing.scss';
@import './components/Header/ProgressIndicator/ProgressIndicator.scss';
@import './components/Header/Header.scss';
@import './components/Main/CardContainer/CardContainer.scss';
@import './components/Main/Intro/Intro.scss';
@import './components/Main/Outro/Outro.scss';
@import './components/Main/Main.scss';
@import './components/SuperHappyIcon/SuperHappyIcon.scss';
@import './components/TextIcon/TextIcon.scss';
