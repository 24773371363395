dialog {
  display: none;

  :focus:not(:active) {
    outline: auto;
  }

  &[open] {
    --max-dialog-width: 44rem;

    background: var(--background);
    border: 0;
    color: var(--plain__enabled__front__default);
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
    grid-template-columns: minmax(auto, var(--max-dialog-width));
    grid-template-areas: 'remark'
      'header'
      'divider'
      'content';
    max-height: calc(100vh - 4rem);
    max-width: var(--max-dialog-width);
    overflow: hidden;
    padding: 0;
  }

  &::backdrop {
    backdrop-filter: blur(0.25rem);
    background-color: hsl(213deg 5% 46% / 25.1%);
  }
}

.m-dialog {

  @include floating-outline;
  
  &__remark {
    grid-area: remark;
    height: rem(6px);

    &.--info {
      background-color: var(--major-signal-neutral__enabled__fill__default);
    }

    &.--success {
      background-color: var(--major-signal-success__enabled__fill__default);
    }

    &.--warning {
      background-color: var(--major-signal-warning__enabled__fill__default);
    }

    &.--error {
      background-color: var(--major-signal-error__enabled__fill__default);
    }
  }

  &__header {
    align-items: center;
    display: flex;
    grid-area: header;
    height: 3rem;
    overflow: hidden;
    padding: 0 2rem;

    > .a-icon {
      margin-right: 0.5rem;
    }

    .a-button--integrated {
      display: flex;
      margin-left: auto;
      margin-right: -2rem;
    }
  }

  &__title {

    @include text-highlight;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // reset margins of divider to achieve design specification
  .a-divider {
    grid-area: divider;
    margin: 0;
  }

  &__content {
    grid-area: content;
    overflow-y: auto;
    padding: 1.5rem 2rem 0;

    &:first-child {
      padding-top: 2rem;
    }

    > *:last-child {
      margin-bottom: 2rem;
    }
  }

  &__headline {

    @include size-xl;
    @include text-highlight;

    margin-bottom: 1rem;
  }

  &__code {

    @include size-s;

    margin-top: 1rem;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1.5rem;

    button {
      width: fit-content;
    }

    .a-button:nth-last-child(2) {
      margin-left: auto;
    }
  }
}
