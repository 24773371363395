.m-bonus-round__intro {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &__icon {
    font-size: 6rem;
    margin-bottom: 1rem;
  }

  &__headline,
  &__kicker {
    @include size-xl;
  }

  &__instructions {
    display: none;
  }

  button {
    margin-bottom: 1rem;
  }
}

@include rb-core-tablet {
  .m-bonus-round__intro {
    &__headline {
      margin-bottom: 1rem;
    }

    &__kicker {
      margin-bottom: 2rem;
    }

    button {
      width: fit-content;
    }

    &__instructions {
      @include size-s;
      display: flex;
    }
  }
}
