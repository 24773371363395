@use 'sass:map';
@use 'sass:meta';

/// Constant that defines distance between hovered, pressed states
/// @type number
$-distanceSize: 10;

// Theme variables:
$-mainColor: null;
$-accentColor: null;
$-name: null;
$-mode: lightMode;

// Background variables:
$-mainLuv: null;
$-direction: null;

/// Configuration of the backgrounds of each theme.
/// Each entry contains the Luv values of the background
/// for a light and dark mode.
/// @type map

$-backgrounds: (
  primary: (
    light: 100,
    dark: 0
  ),
  secondary: (
    light: 95,
    dark: 10
  ),
  contrast: (
    light: 20,
    dark: 20
  ),
  floating: (
    light: 100,
    dark: 10
  )
);

/// Returns `$a` if `$a` is truthy, otherwise `$b`
///
/// @param {any} $a   Returned if truthy
/// @param {any} $b   Returned instead if `$a` is falsy
///
/// @return {any}

@function -nvl($a, $b) {

  @return if($a, $a, $b);
}

/// Returns the CSS `var()` expression referencing to a variable as produced by
/// [palette-editor](https://rb-ux-hellohtml.azurewebsites.net/projects/c-styleguide/palette-editor/)
/// for a color from the palette. `$luv` values of `100` and `0` become
/// `white` and `black` respectively. If `$color` is `none` then `$luv` is ignored
/// and no `var()` expression is produced, but instead `none` is returned – useful
/// for transparent backgrounds.
///
/// @param {string} $color  Color name, e.g. `blue`, `gray`
/// @param {number} $luv    Luv value between 0 (black) and 100 (white)
///
/// @return {string}

@function colorVarName($color, $luv) {

  @if $color == none {

    @return transparent;
  }

  $name: #{$color}-#{$luv};

  @if $luv >= 100 {
    $name: 'white';
  }

  @if $luv <= 0 {
    $name: 'black';
  }

  @return var(--bosch-#{$name});
}

/// Get the direction of an Luv shift to add contrast
/// for a given background Luv.
///
/// @param {number} $luv  Luv value of the background
///
/// @return {number}      Either `-1` for bright backgrounds
///                       or `1` for dark backgrounds

@function -getDirection($luv) {

  @return if($luv > 50, -1, 1);
}

/// Calculates the Luv value for text on a given background.
/// Returns either `black` (Luv = 0) or `white` (Luv = 100).
/// If `$disabled` is true, a lower contrast is produced.
///
/// @param {number} $backgroundLuv  Luv value of the background
/// @param {bool} $disabled         [false] - Whether to generate for disabled text
///
/// @return {number} Luv

@function textLuv($backgroundLuv, $disabled: false) {

  @if $disabled {

    @return -shiftLuv($backgroundLuv, 3.5, -getDirection($backgroundLuv));

  }

  @return if($backgroundLuv > 50, 0, 100);
}

/// Shifts the Luv in the correct direction depending on the
/// given `$direction`. Optional constraints can be set.
///
/// @param {number} $luv        Luv value to shift
/// @param {number} $distance   Steps to shift, is multiplied by the `$-distanceSize` constant
/// @param {number} $direction  Direction to shift, either '-1' or '1'.
///                             If omitted, the current global distance based
///                             on the currently generated background is used
/// @param {number} $atLeast    [null] - If provided then `$luv`
///                             is shifted at least to this luv value or beyond.
///                             This could increase the contrast
/// @param {number} $limit      [null] - If provided then `$luv`
///                             is not shifted beyond this limit. This could reduce the contrast.
///                             If both `$atLeast` and `$limit` are set then `$limit` takes precedence
///
/// @return {number} Luv

@function -shiftLuv($luv, $distance, $direction: $-direction, $atLeast: null, $limit: null) {
  $r: $luv + $direction * $distance * $-distanceSize;

  @if $atLeast != null and if($direction < 0, $r > $atLeast, $r < $atLeast) {
    $r: $atLeast;
  }

  @if $limit != null and if($direction < 0, $r < $limit, $r > $limit) {
    $r: $limit;
  }

  @return $r;
}

/// Completes a swatch config by filling in defaults for missing values
/// and then calculating the final Luv when a distance is given.
/// Removes the distance from the config after applying it.
/// Returns `null` if `$config` is `null`.
///
/// @param {map} $config  Map with the config for a swatch
///
/// @return {map}         Completed swatch config, may be `null`

@function -fillConfig($config) {

  @if $config == null {

    @return null;
  }

  $r: map.merge(
    (
      distance: 0,
      luv: $-mainLuv
    ),
    $config
  );

  
  $luv: -shiftLuv(map.get($r, luv), map.get($r, distance), $atLeast: map.get($r, atLeast));
  
  @return map.merge(map.remove($r, distance), (luv: $luv));
}

/// Generates one single CSS variable name color mapping, e.g.
///
/// `--major-accent__enabled__fill__default: var(--bosch-blue-50);`
///
/// @param {string} $colorSchemeName  `plain`, `neutral`, `small`, …
/// @param {string} $appearanceName   `enabled`, `disabled`, `focused`, …
/// @param {string} $swatchName       `fill` or `front`
/// @param {string} $stateName        `default`, `hovered`, or `pressed`
/// @param {map} $state               Color information to use, e.g. `(color: blue, luv: 50)`

@mixin -generateState($colorSchemeName, $appearanceName, $swatchName, $stateName, $state) {
  $color: map.get($state, color);
  $luv: map.get($state, luv);
  
  --#{$colorSchemeName}__#{$appearanceName}__#{$swatchName}__#{$stateName}: #{colorVarName($color, $luv)};
}

/// Generates color mappings for one swatch.
/// For `enabled` appearance, `hovered` and `pressed` states are generated
/// as well by shifting the Luv. If `$swatch` contains `noShift: true` then Luv
/// will not be shifted for these states.
/// If `$swatch` contains a `default: (map ...)` entry, then this map is used
/// as an override for the `default` state.
///
/// @param {string} $colorSchemeName  `plain`, `neutral`, `small`, …
/// @param {string} $appearanceName   `enabled`, `disabled`, `focused`, …
/// @param {string} $swatchName       `fill` or `front`
/// @param {map} $swatch              Swatch configuration to use

@mixin -generateSwatch($colorSchemeName, $appearanceName, $swatchName, $swatch) {
  // if fill will be generated look for custom distances
  // only run this part if it is the "enabled" fills
  @if $appearanceName == enabled {
    @if $swatchName == fill {
      // selecting mode
      $mode: map-get($swatch, $-mode);
      $modeDistance: null;
      
      @if $mode != null {
        $modeDistance: map-get($mode, distance);
        
        // if background is available use given distance
        @if $modeDistance != null {
          $modeDistance: map-get($modeDistance, $-name);
  
          // if distance is available re-run -fillConfig()
          @if $modeDistance != null {
            $swatch: map.merge(
              $swatch,
              (
                distance: $modeDistance,
                luv: $-mainLuv,
              ),
            );
      
            $swatch: -fillConfig($swatch);
          }
        }
      }
    }
  }
  
  $defaultSwatch: -nvl(-fillConfig(map.get($swatch, default)), $swatch);

  @if ($appearanceName == enabled) {
    $luv: map.get($swatch, luv);
    $noShift: map.get($swatch, noShift);

    // selecting mode
    $mode: map-get($swatch, $-mode);
    $offsets: (hovered: 1, pressed: 2);

    // if mode is available look for offsets of background
    @if $mode != null {
      $modeOffsets: map-get($mode, offsets);

      // if background is available use given offsets
      @if $modeOffsets != null {
        $modeOffsets: map-get($modeOffsets, $-name); 

        @if $modeOffsets != null {
          $offsets: $modeOffsets;
        }
      }
    }

    // Only generate default state if it is not included in the offsets
    @if(map.has-key($offsets, "default") == false) {
      @include -generateState($colorSchemeName, $appearanceName, $swatchName, default, $defaultSwatch);
    }

    @each $stateName, $offset in $offsets {
      $state: if($noShift, $swatch, map.merge($swatch, (luv: -shiftLuv($luv, $offset))));

      @include -generateState($colorSchemeName, $appearanceName, $swatchName, $stateName, $state);
    }
  }
}

/// Generates color mappings for one appearance with all its configured swatches.
///
/// * A `fill` swatch is always generated
/// * A `front` swatch is always generated, either by taking the `front` entry
///     from `$appearance` or by generating a default configuration
///
/// All swatches are automatically adjusted accordingly if `$appearanceName` is `disabled`.
///
/// @param {string} $colorSchemeName  `plain`, `neutral`, `small`, …
/// @param {string} $appearanceName   `enabled`, `disabled`, `focused`, …
/// @param {map} $appearance          Swatch configurations to use

@mixin -generateAppearance($colorSchemeName, $appearanceName, $appearance) {
  $luv: map.get($appearance, luv);

  @if $appearanceName == disabled {
    $color: map.get($appearance, color);
    $luv: -shiftLuv($-mainLuv, if($color == $-mainColor, 1, 2), $limit: $luv);

    @if $colorSchemeName == neutral and $-mainLuv <= 50 {
      $appearance: map.merge(
        $appearance,
        (
          luv: $luv - 5,
          color: if($color != none, $-mainColor, none)
        )
      );
    } @else if $colorSchemeName == neutral and $-mainLuv > 50 {
      $appearance: map.merge(
        $appearance,
        (
          luv: $luv + 5,
          color: if($color != none, $-mainColor, none)
        )
      );
    } @else {
      $appearance: map.merge(
        $appearance,
        (
          luv: $luv,
          color: if($color != none, $-mainColor, none)
        )
      );
    }
    
  }

  // fill
  @include -generateSwatch($colorSchemeName, $appearanceName, fill, $appearance);

  // front
  $front: map.merge(
    (
      color: $-mainColor
    ),
    -nvl(
      map.get($appearance, front),
      (
        noShift: true
      )
    )
  );

  @if map.get($front, noShift) {
    $front: map.merge((luv: textLuv($luv)), $front);
  }
  $front: -fillConfig($front);

  @if $appearanceName == disabled {
    $default: map.get($front, default);

    @if $default {
      $default: map.merge($default, (luv: textLuv($luv, true)));
      $front: map.merge($front, (default: $default));
    }

    @else {
      $front: map.merge($front, (luv: textLuv($luv, true)));
    }

    $front: map.merge($front, (color: $-mainColor));
  }

  @include -generateSwatch($colorSchemeName, $appearanceName, front, $front);
}

/// Generates all color mappings for one background.
/// Surrounds the mappings with a corresponding class selector.
/// For the `primary` background, the `:root` selector is applied in addition.
///
/// @param {string} $backgroundName   `primary`, `secondary`, `contrast`, `floating`, …
///                                   See `$-backgrounds`
/// @param {map} $colorSchemes        Map of all color schemes in the theme

@mixin -generateBackground($backgroundName, $colorSchemes) {
  #{if($backgroundName == primary, if(&, "&, ", ":root, "), "") + ".-" + $backgroundName} {
    // background
    $backgroundState: -fillConfig((color: $-mainColor));
    $color: map.get($backgroundState, color);
    $luv: map.get($backgroundState, luv);

    --background: #{colorVarName($color, $luv)};

    @each $colorSchemeName, $colorScheme in $colorSchemes {
      $colorSchemeFilled: -fillConfig($colorScheme);

      // enabled
      @include -generateAppearance($colorSchemeName, enabled, $colorSchemeFilled);

      // focused
      $focused: map.get($colorScheme, focused);

      @if $focused {
        $focused: -fillConfig(map.merge(map.remove($colorScheme, focused), $focused));

        @include -generateAppearance($colorSchemeName, focused, $focused);
      }

      // disabled
      @if map.get($colorScheme, disabled) != false {
        @include -generateAppearance($colorSchemeName, disabled, $colorSchemeFilled);
      }
    }

    // define background-color and text color for backgrounds
    background-color: var(--background);
    color: var(--plain__enabled__front__default);
  }
}

/// Generate CSS variable definitions for a theme and for all 4 backgrounds.
/// For each background, a class selector will be generated: `-primary`, `-secondary`,
/// `-contrast`, `-floating`, `-floating-shadow-s` and `-floating-shadow-m`. Variables will be inside those classes.
/// You can only use Bosch colors as the color names for `$mainColor` and `$accentColor`.
///
/// @param {string} $mainColor    Desired main color of the theme
/// @param {string} $accentColor  Desired accent color of the theme
/// @param {bool} $dark           [false] - Whether to generate a dark theme


@function distanceLightDark($varDistanceLight, $varDistanceDark) {
  @if $-mainLuv <= 50 {
    @return $varDistanceDark;
  } @else {
    @return $varDistanceLight;
  }
}

@mixin generateTheme($mainColor, $accentColor, $dark: false) {
  $-mainColor: $mainColor !global;
  $-accentColor: $accentColor !global;

  #{if(&, "&", ":root")} {
    --shadow-fill: #{if($dark, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, .25))};
    --floating-outline: #{if($dark, rgba(255, 255, 255, 0.20), rgba(0, 0, 0, .05))};
  }

  // Different direction for light an dark mode

  @each $name, $luvs in $-backgrounds {

    $-mainLuv: map.get($luvs, if($dark, dark, light)) !global;
    $-direction: -getDirection($-mainLuv) !global;
    $-mode: if($dark, darkMode, lightMode) !global;
    $-name: $name !global;
    

    // Individaul text luv for dark mode

    $modifiedFrontLuv: null;

    @if $-mainLuv <= 50 and $-mode == darkMode {
      $modifiedFrontLuv: 95;
    } @else {
      $modifiedFrontLuv: textLuv($-mainLuv);
    }

    // Invert main text luv

    $invTextLuv: (textLuv($-mainLuv)-100) * -1;


    /// TIPPS:
    /// 
    /// FRONT COLOR IS BACKGROUND COLOR:
    /// front: (luv: $-mainLuv, noShift: true),
    /// 
    /// 

    $colorSchemes: (
      plain: (
        color: $mainColor,
        front: (
          luv: $modifiedFrontLuv, 
          noShift: true
        ),
        default: (
          color: none
        ),
        focused: (
          default: (
            color: $accentColor,
            distance: 0.5
          )
        )
      ),
      neutral: (
        color: $mainColor,
        distance: 1,
        focused: (
          color: $accentColor
        ),
      ),
      small: (
        color: $mainColor,
        distance: 5,
        front: (
          luv: $-mainLuv, 
          noShift: true
        ),
      ),
      integrated: (
        color: none,
        front: (
          color: $accentColor,
          distance: distanceLightDark(4,5),
          default: (
            color: $mainColor,
            luv: $modifiedFrontLuv, 
          )
        )
      ),
      minor-accent: (
        color: $accentColor,
        default: (
          color: none
        ),
        front: (
          color: $accentColor,
          distance: distanceLightDark(5,6),
        ),
      ),
      major-accent: (
        color: $accentColor,
        luv: 50,
        front: (
          color: $accentColor,
          luv: $invTextLuv,
          noShift: true
        ),
        lightMode: (
          offsets: (
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            secondary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            floating: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
      ),
      minor-signal-neutral: (
        color: blue,
        distance: 1,
        disabled: false,
        lightMode: (
          offsets: (
            contrast: (
              default: 0,
              hovered: 1,
              pressed: 2,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1.5,
              hovered: 2.5,
              pressed: 3.5,
            ),
            secondary: (
              default: 0.5,
              hovered: 1.5,
              pressed: 2.5,
            ),
            contrast: (
              default: 0,
              hovered: 1,
              pressed: 2,
            ),
            floating: (
              default: 0.5,
              hovered: 1.5,
              pressed: 2.5,
            ),
          ),
        ),
      ),
      minor-signal-error: (
        color: red,
        distance: 1,
        disabled: false,
        lightMode: (
          offsets: (
            contrast: (
              default: 0,
              hovered: 1,
              pressed: 2,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1.5,
              hovered: 2.5,
              pressed: 3.5,
            ),
            secondary: (
              default: 0.5,
              hovered: 1.5,
              pressed: 2.5,
            ),
            contrast: (
              default: 0,
              hovered: 1,
              pressed: 2,
            ),
            floating: (
              default: 0.5,
              hovered: 1.5,
              pressed: 2.5,
            ),
          ),
        ),
      ),
      minor-signal-warning: (
        color: yellow,
        distance: 1,
        disabled: false,
        lightMode: (
          offsets: (
            contrast: (
              default: 0,
              hovered: 1,
              pressed: 2,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 2,
              hovered: 3,
              pressed: 4,
            ),
            secondary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            contrast: (
              default: 0,
              hovered: 1,
              pressed: 2,
            ),
            floating: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
      ),
      minor-signal-success: (
        color: green,
        distance: 1,
        disabled: false,
        lightMode: (
          offsets: (
            contrast: (
              default: 0,
              hovered: 1,
              pressed: 2,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1.5,
              hovered: 2.5,
              pressed: 3.5,
            ),
            secondary: (
              default: 0.5,
              hovered: 1.5,
              pressed: 2.5,
            ),
            contrast: (
              default: 0,
              hovered: 1,
              pressed: 2,
            ),
            floating: (
              default: 0.5,
              hovered: 1.5,
              pressed: 2.5,
            ),
          ),
        ),
      ),
      major-signal-neutral: (
        color: blue,
        luv: 50,
        disabled: false,
        front: (
          luv: $invTextLuv,
          noShift: true
        ),
        lightMode: (
          offsets: (
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            secondary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            floating: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
      ),
      major-signal-error: (
        color: red,
        luv: 55,
        disabled: false,
        front: (
          luv: $invTextLuv,
          noShift: true
        ),
      ),
      major-signal-warning: (
        color: yellow,
        luv: 85,
        disabled: false,
        lightMode: (
          offsets: (
            contrast: (
              default: -0.5,
              hovered: 0.5,
              pressed: 1,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: -0.5,
              hovered: 0.5,
              pressed: 1,
            ),
            secondary: (
              default: -0.5,
              hovered: 0.5,
              pressed: 1,
            ),
            contrast: (
              default: -0.5,
              hovered: 0.5,
              pressed: 1,
            ),
            floating: (
              default: -0.5,
              hovered: 0.5,
              pressed: 1,
            ),
          ),
        ),
      ),
      major-signal-success: (
        color: green,
        luv: 50,
        disabled: false,
        front: (
          luv: $invTextLuv,
          noShift: true
        ),
        lightMode: (
          offsets: (
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            secondary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            floating: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
      ),
      minor-highlight-purple: (
        color: purple,
        disabled: false,
        default: (
          color: none
        ),
        front: (
          color: purple,
          distance: distanceLightDark(5,6),
        )
      ),
      minor-highlight-blue: (
        color: blue,
        disabled: false,
        default: (
          color: none
        ),
        front: (
          color: blue,
          distance: distanceLightDark(5,6),
        )
      ),
      minor-highlight-turquoise: (
        color: turquoise,
        disabled: false,
        default: (
          color: none
        ),
        front: (
          color: turquoise,
          distance: distanceLightDark(5,6),
        )
      ),
      minor-highlight-green: (
        color: green,
        disabled: false,
        default: (
          color: none
        ),
        front: (
          color: green,
          distance: distanceLightDark(5,6),
        )
      ),
      major-highlight-purple: (
        color: purple, 
        luv: 40,
        disabled: false,
        front: (
          luv: $invTextLuv,
          noShift: true
        ),
        lightMode: (
          offsets: (
            contrast: (
              default: 1.5,
              hovered: 2.5,
              pressed: 3.5,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            secondary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            contrast: (
              default: 1.5,
              hovered: 2.5,
              pressed: 3.5,
            ),
            floating: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
      ),
      major-highlight-blue: (
        color: blue, 
        luv: 50,
        disabled: false,
        front: (
          luv: $invTextLuv,
          noShift: true
        ),
        lightMode: (
          offsets: (
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            secondary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            floating: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
      ),
      major-highlight-turquoise: (
        color: turquoise, 
        luv: 50,
        disabled: false,
        front: (
          luv: $invTextLuv,
          noShift: true
        ),
        lightMode: (
          offsets: (
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            secondary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            floating: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
      ),
      major-highlight-green: (
        color: green,
        luv: 50,
        disabled: false,
        front: (
          luv: $invTextLuv,
          noShift: true
        ),
        lightMode: (
          offsets: (
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
        darkMode: (
          offsets: (
            primary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            secondary: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            contrast: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
            floating: (
              default: 1,
              hovered: 2,
              pressed: 3,
            ),
          ),
        ),
      ),
    );

    @include -generateBackground($name, $colorSchemes);
  }
}