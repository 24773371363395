/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable a11y/line-height-is-vertical-rhythmed */

a,
.a-link {
  span:last-of-type:not(:first-of-type) {
    display: inline-block;
  }

  .a-icon {
    display: inline-block;
    font-size: calc(var(--font-size) * var(--line-height));
    line-height: 1;
    margin-right: calc(var(--font-size) * var(--line-height) / 3);
    vertical-align: bottom;
  }

  span .a-icon {
    margin-left: calc(var(--font-size) * var(--line-height) / 3);
    margin-right: unset;
  }
}

a {
  display: inline;
  color: var(--minor-accent__enabled__front__default);

  span + span {
    // This is needed for inline links to be completly underlined. it will be overwritten for .a-link later on
    text-decoration: underline;
  }

  &:hover {
    color: var(--minor-accent__enabled__front__hovered);
  }

  &:active {
    color: var(--minor-accent__enabled__front__pressed);
  }
}

.a-link {
  display: block;
  width: fit-content;
  color: var(--minor-accent__enabled__front__default);

  /*
    The next block is needed for multi-line-links to have the correct padding.
    It should only apply to links with an icon and iconPosition = left.
    */
  > a {
    > i {
      position: absolute;

      + span {
        display: inline-block;

        /*
          This calculation is needed to get the correct spacing between the icon and the text.
          Since the icon is positioned 'absolute' in this case, we need to add the icon's width
          to the default spacing between the icon and the link.
        */
        --icon-size: calc(1em * var(--line-height));
        --default-spacing: calc(1em * var(--line-height) / 3);

        padding-left: calc(var(--icon-size) + var(--default-spacing));
      }
    }
  }

  &:not(.-icon, .a-link--button, .a-link--button-secondary, .a-link--integrated, .-disabled) a {
    text-decoration: underline;
  }

  a {
    display: inline-block;
    color: currentColor;
    text-decoration: none;

    span,
    &:visited {
      text-decoration: none;
    }
  }

  &:hover {
    color: var(--minor-accent__enabled__front__hovered);

    a {
      text-decoration: underline;

      span:last-of-type:not(:first-of-type) {
        text-decoration: underline;
      }

      > i + span {
        text-decoration: underline;
      }
    }
  }

  &:active {
    color: var(--minor-accent__enabled__front__pressed);
  }

  &.-disabled,
  &.-disabled a {
    pointer-events: none;
    color: var(--minor-accent__disabled__front__default);
  }
}

.a-link--integrated {
  a {
    color: var(--integrated__enabled__front__default);

    &:hover {
      color: var(--integrated__enabled__front__hovered);
    }

    &:active {
      color: var(--integrated__enabled__front__pressed);
    }
  }

  &.-disabled,
  &.-disabled a {
    pointer-events: none;
    color: var(--integrated__disabled__front__default);
  }

  &.highlight:hover {
    a,
    span:last-of-type:not(:first-of-type) {
      text-decoration: none;
    }
  }
}

.a-link--button,
%a-link--button {
  background-color: var(--major-accent__enabled__fill__default);
  color: var(--major-accent__enabled__front__default);
  cursor: pointer;

  a {
    color: currentColor;
    padding: 0.75rem 1rem;
  }

  &:hover {
    background-color: var(--major-accent__enabled__fill__hovered);
    color: var(--major-accent__enabled__front__default);

    a {
      color: currentColor;
      text-decoration: none;

      span,
      > i + span,
      span:last-of-type:not(:first-of-type) {
        text-decoration: none;
      }
    }
  }

  &:active {
    background-color: var(--major-accent__enabled__fill__pressed);
  }

  &.-disabled {
    background-color: var(--major-accent__disabled__fill__default);
    color: var(--major-accent__disabled__front__default);

    a {
      color: currentColor;
    }
  }
}

.a-link--button-secondary {

  @extend %a-link--button;

  background-color: var(--minor-accent__enabled__fill__default);
  color: var(--minor-accent__enabled__front__default);
  border: 1px solid var(--minor-accent__enabled__front__default);

  &:hover {
    background-color: var(--minor-accent__enabled__fill__hovered);
    color: var(--minor-accent__enabled__front__hovered);
    border-color: var(--minor-accent__enabled__front__hovered);

    a {
      span,
      > i + span,
      span:last-of-type:not(:first-of-type) {
        text-decoration: none;
      }
    }
  }

  &:active {
    background-color: var(--minor-accent__enabled__fill__pressed);
    color: var(--minor-accent__enabled__front__pressed);
    border-color: var(--minor-accent__enabled__front__pressed);
  }

  &.-disabled {
    background-color: var(--minor-accent__disabled__fill__default);
    color: var(--minor-accent__disabled__front__default);
    border-color: var(--minor-accent__disabled__front__default);
  }
}
