.m-bonus-round {
  background-color: var(--small__enabled__front__default);
  padding: 1.5rem 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: 100vw;
}

@include rb-core-tablet {
  .a-modal__bonus-round {
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
  }

  .m-bonus-round {
    @include tablet-grid;
    height: auto;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 608px;
  }
}
