.m-feedback {
  @include mobile-grid;

  grid-column: span 4;
  margin-bottom: 1rem;

  .a-super-happy-icon,
  .a-icon,
  &__text {
    grid-column: span 4;
  }

  &__text {
    @include size-xl;
  }

  .a-super-happy-icon,
  .a-icon {
    margin-bottom: 0.5rem;
  }

  .a-super-happy-icon {
    fill: var(--major-signal-success__enabled__fill__default);
    height: 3rem;
    width: 3rem;
  }

  .a-icon {
    @include size-4xl;
    color: var(--major-signal-error__enabled__fill__default);
    line-height: normal;
  }
}

@include rb-core-tablet {
  .m-feedback {
    @include tablet-grid;
    grid-column: span 12;

    &__text,
    .a-icon,
    .a-super-happy-icon {
      grid-column: span 12;
    }

    &__text {
      @include size-2xl;
    }
  }
}
