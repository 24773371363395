// Definition of custom fonts

@font-face {
  font-family: 'boschsans';
  font-weight: 400;
  src: url(#{$font-base-path}/boschsans_regular.woff2) format('woff2'), url(#{$font-base-path}/boschsans_regular.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'boschsans';
  font-weight: 700;
  src: url(#{$font-base-path}/boschsans_bold.woff2) format('woff2'), url(#{$font-base-path}/boschsans_bold.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'boschsans';
  font-weight: 400;
  src: url(#{$font-base-path}/boschsans_regularItalic.woff2) format('woff2'), url(#{$font-base-path}/boschsans_regularItalic.woff) format('woff');
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'boschsans';
  font-weight: 700;
  src: url(#{$font-base-path}/boschsans_boldItalic.woff2) format('woff2'), url(#{$font-base-path}/boschsans_boldItalic.woff) format('woff');
  font-display: swap;
  font-style: italic;
}
