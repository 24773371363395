.m-card-container {
  grid-column: span 4;
  margin-top: 4rem;

  @include mobile-grid;

  p,
  .m-cards {
    grid-column: span 4;
  }

  h2 {
    @include size-xl;

    grid-column: span 3;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
  }

  .m-card {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .a-link {
      width: 100%;
    }
  }
}

@include rb-core-tablet {
  .m-card-container {
    grid-column: span 12;
    margin-top: 3.5rem;

    @include tablet-grid;

    h2,
    p,
    .m-cards {
      grid-column: span 12;
    }

    h2 {
      @include size-2xl;
    }

    .m-cards {
      display: flex;
      justify-content: flex-start;
    }

    .m-card:not(:last-child) {
      margin-bottom: unset;
      margin-right: 2rem;
    }
  }
}
