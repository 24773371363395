@mixin base-grid {
  display: grid;
  column-gap: 2rem;
}

@mixin mobile-grid {

  @include base-grid;

  grid-template-columns: repeat(4, 1fr);
}

@mixin tablet-grid {
  grid-template-columns: repeat(12, 1fr);
}

@mixin desktop-grid {
  grid-template-columns: repeat(12, 1fr);
}
