.m-guessing {
  @include mobile-grid;

  &__headline,
  .a-image,
  &__buttons,
  .a-result,
  .m-slider-points,
  .a-notification,
  button:not(:last-child) {
    grid-column: span 4;
    margin-bottom: 1rem;
  }

  &__headline {
    @include size-xl;
  }

  .a-notification {
    margin-bottom: 2rem;
  }

  .m-slider-points {
    align-items: center;
    display: flex;
    margin-bottom: 2rem;

    .a-slider {
      height: 1.5rem;
    }

    &__slider-container {
      flex: 1;
    }

    &__result {
      margin-left: 1rem;
    }
  }
}

@include rb-core-tablet {
  .m-guessing {
    grid-template-areas:
      'headline image'
      'slider slider'
      'notification notification'
      'buttons buttons';
    grid-template-columns: repeat(12, 1fr);

    .a-result,
    .a-notification {
      grid-column: span 12;
    }

    &__headline {
      @include size-2xl;
      grid-area: headline;
      grid-column: span 7;
    }

    .a-image {
      grid-area: image;
      grid-column: span 5;
    }

    .m-slider-points {
      grid-area: slider;
      grid-column: span 12;
    }

    &__buttons {
      display: flex;
      grid-area: buttons;
      grid-column: span 12;
      margin-bottom: unset;

      button {
        width: fit-content;

        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
  }
}
