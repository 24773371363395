.m-choice-question {
  @include mobile-grid;

  &__headline,
  &__clue,
  &__image,
  &__list,
  &__buttons,
  &__buttons .a-button,
  .a-link,
  .a-notification {
    grid-column: span 4;
  }

  &__headline,
  &__clue,
  &__image,
  &__list-item:not(:last-child),
  &__list-item__image,
  &__buttons .a-button--primary {
    margin-bottom: 1rem;
  }

  h1 {
    @include size-xl;
  }

  &__list,
  &__list.-multiple.-images &__list-item:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__image img {
    position: relative;
  }

  &__list-item {
    // After the question is acknowledged, reset the cursor's look
    &.-correct,
    &.-incorrect {
      cursor: unset;
    }

    &.-incorrect > .m-choice-question__list-item__radio-button,
    &.-incorrect > .m-choice-question__list-item__checkbox {
      opacity: 0.5;
    }
  }

  &__buttons {
    @include mobile-grid;
  }
}

@include rb-core-tablet {
  .m-choice-question {
    @include tablet-grid;

    &__headline,
    &__clue,
    &__image,
    &__list,
    &__buttons,
    .a-notification {
      grid-column: span 12;
    }

    &__list.-single.-images {
      column-gap: 2rem;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    }
    &__list.-multiple.-images {
      @include base-grid;
      @include tablet-grid;

      .m-choice-question__list-item:nth-child(3),
      .m-choice-question__list-item:nth-child(4) {
        margin-bottom: 0;
      }
    }

    &__list-item {
      grid-column: span 6;
    }

    h1 {
      @include size-2xl;
    }

    &__buttons {
      column-gap: unset;
      display: flex;

      .a-button {
        margin-bottom: 0;
        width: fit-content;

        &--primary {
          margin-right: 1rem;
        }
      }
    }
  }
}
