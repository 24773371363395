@use "sass:math";
/// This file contains basic css styling / functions / mixin / variables

/// Function to calculate correct rem size
@function rem($px, $base: 16px) {

  @return math.div($px, $base) * 1rem;
}

/// mixin to generate box shadow and dimmed background
@mixin box-shadow {
  box-shadow: 0 0 0.5rem 0.1rem var(--shadow-fill);
}

@mixin background-dimmed {
  /// because we can use CSS custom properties here,
  /// we need to implement here the hex value with alpha channel directly
  background-color: #71767c40;
  backdrop-filter: blur(rem(4px));
}

@mixin floating-outline {
  outline: 1px solid var(--floating-outline);
}

/// basic styling
html,
body {
  font-family: boschsans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  font-size: 16px;
  --font-size: 1rem;
  line-height: 1.5;
  --line-height: 1.5;
  font-weight: 400;
}

/// We need the extra selectors, because the user agent stylesheet overwrites the normal body font-family
input,
textarea,
select,
button {
  font-family: inherit;
  border-radius: 0;
}

/// Set all elements to box-sizing:
*,
*::before,
*::after {
  box-sizing: border-box;
}
