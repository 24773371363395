.m-finding-things {
  @include mobile-grid;

  &__headline,
  &__image-container,
  &__buttons,
  .a-notification {
    grid-column: span 4;
  }

  &__headline,
  &__buttons .a-button--primary {
    margin-bottom: 1rem;
  }

  &__image-container {
    margin-bottom: 2rem;
  }

  &__headline {
    @include size-xl;
  }

  &__image {
    position: relative;
  }

  &__badges {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      display: inline-block;
    }
  }

  .a-notification.-neutral {
    margin-bottom: 0;
    margin-top: 2rem;
  }
}

@include rb-core-tablet {
  .m-finding-things {
    @include tablet-grid;

    &__headline {
      @include size-2xl;
    }

    &__headline,
    &__image-container,
    &__image,
    &__buttons,
    .a-notification {
      grid-column: span 12;
    }

    &__buttons {
      display: flex;

      .a-button {
        width: fit-content;

        &:first-of-type {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
  }
}
