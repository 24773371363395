.m-outro {
  @include mobile-grid;

  h1,
  .a-image,
  &__paragraph,
  &__disclaimer,
  button {
    grid-column: span 4;
  }

  h1 {
    @include size-xl;
    margin-bottom: 1rem;
  }

  .a-image {
    background: #e1e2e5;
    height: 16rem;
    position: relative;

    &__ratioWrapper {
      aspect-ratio: 2 /1;
      height: 100%;
      padding-top: unset;
      width: 100%;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;

    h2,
    h3 {
      @include size-xl;
    }

    h3 {
      @include text-regular;
    }

    &__top {
      padding: 1rem;
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
    }
  }

  &__score {
    font-size: 2.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
  }

  &__textIcons {
    background: #e1e2e5;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;

    &::before {
      background: linear-gradient(rgb(225, 226, 229, 0), rgb(225, 226, 229, 1));
      bottom: 100%;
      content: "";
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 100;
    }
    .a-text-icon {
      justify-content: center;
      position: relative;
      z-index: 200;
    }
  }

  &__paragraph {
    margin-bottom: 1rem;
  }

  .a-image {
    margin-bottom: 4rem;
  }

  .a-button--tertiary {
    justify-content: flex-start;
    position: absolute;
    bottom: -3rem;
  }

  &__disclaimer {
    @include size-s;
    margin-bottom: 2rem;
  }
}

@include rb-core-tablet {
  .m-outro {
    @include tablet-grid;

    grid-template-areas:
      'h1 image'
      'paragraph image'
      'disclaimer disclaimer'
      'button button';

    h1 {
      @include size-2xl;
      grid-area: h1;
    }

    &__paragraph {
      @include size-l;
      grid-area: paragraph;
      margin-bottom: 1rem;
    }

    &__disclaimer {
      @include size-m;
      grid-area: disclaimer;
    }

    &__textIcons {
      display: flex;

      .a-text-icon {
        min-width: 7rem;
      }
    }

    &__score {
      padding-bottom: unset;
    }

    .a-image {
      grid-area: image;
      grid-column: span 7;
      height: fit-content;
      margin-bottom: 0;

      &__ratioWrapper {
        padding-top: 56.25%;
      }
    }

    h1,
    &__paragraph,
    &__disclaimer {
      grid-column: span 5;
    }

    .a-button--primary {
      grid-area: button;
      width: max-content;
    }
  }
}
