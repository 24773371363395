.a-super-happy-icon {

  polygon {
    animation: super-happy-icon-eyes 1.5s ease-in forwards infinite;
  }
  
  path:last-child {
    animation: super-happy-mouth 10s ease-in forwards infinite;
  }
}

@keyframes super-happy-icon-eyes {
  0% {transform: translateY(rem(2px))}
  15% {transform: translateY(rem(-2px))}
  22% {transform: translateY(rem(3px))}
  30% {transform: translateY(rem(-2px)) rotate(-1deg)}
  40% {transform: translateY(rem(2px))}
  48% {transform: translateY(rem(-3px))}
  56% {transform: translateY(rem(2px))}
}

@keyframes super-happy-mouth {
  0% {transform: scaleY(1) translateY(0)}
  17% {transform: scaleY(1) translateY(0)}
  20% {transform: scaleY(1.2) rotate(0deg) translateY(rem(-20px))}
  23% {transform: scaleY(1) translateY(0)}
}