.m-bonus-round__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &__textIcons {
    display: flex;

    .a-text-icon:first-of-type {
      margin-right: 1rem;
    }
  }

  &__textIcons,
  &__headline,
  &__image-container,
  &__feedback-icon button:first-of-type {
    margin-bottom: 1rem;
  }

  &__feedback {
    align-items: center;
    animation: fade linear 1s;
    animation-iteration-count: 1;
    display: inline-flex;
    font-size: 3rem;
    margin-bottom: 1rem;
    opacity: 0;

    span {
      @include size-xl;
      margin-left: 1rem;
    }

    i {
      font-size: 3rem;
    }

    &.-bad {
      color: var(--major-signal-error__enabled__fill__default);
    }

    &.-good {
      color: var(--major-signal-success__enabled__fill__default);

      svg {
        fill: var(--major-signal-success__enabled__fill__default);
        height: 3rem;
        width: 3rem;
      }
    }
  }
}

@include rb-core-tablet {
  .m-bonus-round__content {
    &__feedback {
      flex: 1;
      margin: 0;
    }

    &__feedback-icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__buttons {
      display: flex;

      button {
        height: fit-content;
        width: fit-content;

        &:first-of-type {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
