.m-bonus-round__outro {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .a-text-icon,
  &__kicker {
    margin-bottom: 1rem;
  }

  &__headline {
    margin-bottom: 0.5rem;
  }

  &__score {
    display: flex;

    i {
      font-size: 4rem;
      margin-right: 0.5rem;
    }
  }
}

@include rb-core-tablet {
  .m-bonus-round__outro {

    .a-text-icon {
      justify-content: flex-start;
    }

    &__score {
      margin-bottom: 1rem;
    }

    button {
      width: fit-content;
    }
  }
}
