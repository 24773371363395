.a-badge {
  align-items: center;
  background-color: var(--major-accent__enabled__fill__default);
  border-radius: 50%;
  color: var(--major-accent__enabled__front__default);
  display: inline-flex;
  justify-content: center;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: -0.75rem;
  margin-top: -0.75rem;

  &__icon {
    font-size: 1.125rem;
    font-weight: bold !important;
  }
}

@include rb-core-tablet {
  .a-badge {
    height: 3rem;
    width: 3rem;
    margin-left: -1.5rem;
    margin-top: -1.5rem;

    &__icon {
      font-size: 2.5rem;
    }
  }
}
