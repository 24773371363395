$progress-indicator_indeterminate_width: 2rem;
$progress-indicator_height: 0.5rem;

@mixin dynamic-bar-animation-mixin {
  @keyframes dynamic-bar-animation {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(
        calc(100% - $progress-indicator_indeterminate_width)
      );
    }
  }
}

.a-progress-indicator-container,
.a-progress-indicator__inner-bar,
progress {
  height: $progress-indicator_height;
}

.a-progress-indicator-container {
  margin: 1.25rem 0;
  min-width: 8rem;
  overflow: hidden; // Firefox's styling support
  position: relative;
}

progress {
  appearance: none; // Safari's styling support
  background-color: var(--minor-accent__enabled__fill__default);
  border: 0; // Firefox support
  box-shadow: inset 0 0 0 0.0625rem var(--minor-accent__enabled__front__default); // Firefox's styling support
  display: block;
  min-width: 8rem;
  width: 100%;

  // Chrome's styling support
  &::-webkit-progress-bar {
    background-color: var(--minor-accent__enabled__fill__default);
    box-shadow: inset 0 0 0 0.0625rem
      var(--minor-accent__enabled__front__default);
  }

  &::-webkit-progress-value {
    background-color: var(--minor-accent__enabled__front__default);
  }

  // Firefox's styling support
  &.-indeterminate::-moz-progress-bar {
    background-color: var(--minor-accent__enabled__fill__default);
    box-shadow: inset 0 0 0 0.0625rem
      var(--minor-accent__enabled__front__default);
  }
}

.a-progress-indicator {
  position: absolute;
  transform: translateZ(0);

  &__anim-bar {
    @include dynamic-bar-animation-mixin;

    animation: dynamic-bar-animation 2s infinite linear;
  }

  &.-indeterminate
    + .a-progress-indicator__anim-bar
    .a-progress-indicator__inner-bar {
    width: $progress-indicator_indeterminate_width;
  }

  &__inner-bar {
    background-color: var(--minor-accent__enabled__front__default);
    position: absolute;
  }
}
