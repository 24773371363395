dialog[open] {
  max-height: unset;
}

.m-dialog {
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999;

  &__headline .a-icon {
    font-size: 2rem;
    margin: 0 0.5rem 0 rem(-3px);
  }

  &__header button {
    width: auto;
  }

  .a-link {
    width: fit-content;
  }
}

@include rb-core-tablet {
  .m-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;

    &__header button {
      margin-right: 0;
      top: 0;
    }
  }

  .m-choice-question .m-dialog__content button {
    margin-right: 0;
  }

  .a-modal-background {
    background: rgba(113, 118, 124, 0.25);
    backdrop-filter: blur(0.25rem);
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
  }
}
