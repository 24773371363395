/* stylelint-disable no-descending-specificity */
/* stylelint-disable a11y/content-property-no-static-value */

@mixin thumb-base {
  border-radius: 50%;
  height: 1.5rem;
  margin-top: rem(-11.5px);
  position: relative;
  width: 1.5rem;
  z-index: 999;
}

.a-slider {
  --slider-percentage: 50; // This initial value will be overwritten by JS.

  height: rem(48px);
  width: auto;

  div {
    display: flex;
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    margin: 0.5rem 0.75rem;
  }

  display: flex;
  align-items: center;

  input {
    background: transparent;
    height: 1.5rem;
    width: 100%;
    -webkit-appearance: none;
    outline: 0;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: ' ';
      left: 0;
      right: 0;
      height: 0.125rem;
      top: 0.6875rem; // (thumbHeight - trackHeight) / 2 --> (1.5 - 0.125) / 2
    }

    // Even if the styling is the same, custom colors for Firefox need to be separated from Chrome.

    // Styling for the part not in progress of the slider.
    // Chrome
    &::before {
      background: var(--small__enabled__fill__default);
    }

    // Firefox
    &::-moz-range-track {
      background: var(--small__enabled__fill__default);
      height: 0.13rem;
    }

    // Styling for the part in progress of the slider.
    // Chrome
    &::after,
    &::-webkit-slider-thumb {
      background: var(--major-accent__enabled__fill__default);
      width: calc(var(--slider-percentage) * 1%);
    }

    // Firefox
    &::-moz-range-progress,
    &::-moz-range-thumb {
      background: var(--major-accent__enabled__fill__default);
    }

    &:hover {
      // Chrome
      &::after,
      &::-webkit-slider-thumb {
        background: var(--major-accent__enabled__fill__hovered);
      }

      // Firefox
      &::-moz-range-progress,
      &::-moz-range-thumb {
        background: var(--major-accent__enabled__fill__hovered);
      }
    }

    &:active {
      // Chrome
      &::after,
      &::-webkit-slider-thumb {
        background: var(--major-accent__enabled__fill__pressed);
      }

      // Firefox
      &::-moz-range-progress,
      &::-moz-range-thumb {
        background: var(--major-accent__enabled__fill__pressed);
      }
    }

    &:disabled {
      &::after,
      &::-webkit-slider-thumb {
        background: var(--major-accent__disabled__fill__default);
      }

      &::-moz-range-thumb,
      &::-moz-range-progress {
        background: var(--major-accent__disabled__fill__default);
      }
    }

    // Chrome
    &::-webkit-slider-thumb {
      @include thumb-base;

      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      height: 0.125rem;
    }

    // Firefox
    &::-moz-range-thumb {
      @include thumb-base;

      border: 0;
    }
  }

  .a-tooltip {
    @include floating-outline;
    text-align: center;
    position: absolute;
    bottom: 2.25rem;
    left: calc(50% - 1.9375rem);
    position: absolute;
    text-align: center;
    visibility: hidden;
    white-space: nowrap;
  }

  label {
    font-size: rem(16px);
    flex: 0 1 auto;
  }

  &.a-slider--labels-on-top {
    flex-wrap: wrap;

    // the labels should not shrink below 50%, but can grow as much as they want
    > label {
      order: 1;
      flex: 1 0 50%;
    }

    // the div containing the input should not shrink below 100%, and should be
    // the third element, visually
    // remove the margins around the input so that it aligns with the label's edges
    > div,
    > input {
      order: 3;
      flex: 1 0 100%;
      margin-left: 0;
      margin-right: 0;
    }

    // the label after the input container should be the second element (visually),
    // and the text should right-align
    > div + label,
    > input + label {
      order: 2;
      text-align: right;
    }

    > input {
      margin: rem(8px) 0;
    }
  }

  &--vertical {
    transform: rotate(270deg);

    &:has(label) {
      padding-right: 1.75rem;
    }

    label {
      position: absolute;
      left: 8.5625rem;
      top: -1.75rem;
      transform: rotate(90deg);
      transform-origin: left bottom;
    }

    .a-tooltip {
      bottom: -3.8rem;
      display: flex;
      transform: rotate(90deg);
    }
  }

  &--unitless .a-tooltip {
    bottom: -3.5rem;
    left: 2.25rem;
  }
}
