.m-intro {
  @include mobile-grid;

  h1,
  .a-image,
  &__paragraph,
  &__disclaimer,
  &__buttons {
    grid-column: span 4;
  }

  h1,
  .a-image,
  &__paragraph,
  &__buttons button {
    margin-bottom: 1rem;
  }

  h1 {
    @include size-2xl;
  }

  &__disclaimer {
    margin-bottom: 2rem;

    @include size-s;
  }

  &__buttons .a-dropdown {
    margin-bottom: 1rem;
  }
}

@include rb-core-tablet {
  .m-intro {
    @include tablet-grid;

    grid-template-areas:
      'h1 image'
      'paragraph image'
      'disclaimer image'
      'button button';
    grid-template-columns: repeat(12, 1fr);

    h1 {
      @include size-4xl;
      grid-area: h1;
    }

    &__paragraph {
      @include size-l;
      grid-area: paragraph;
      min-height: rem(100px);
      margin-bottom: 1rem;
    }

    &__disclaimer {
      @include size-m;
      grid-area: disclaimer;
      height: rem(104px);
    }

    .a-image {
      grid-area: image;
      grid-column: span 7;
    }

    h1,
    &__paragraph,
    &__disclaimer {
      grid-column: span 5;
    }

    &__buttons {
      display: flex;
      grid-area: button;
      width: max-content;

      :first-child {
        margin-right: 1rem;
      }

      .a-dropdown,
      button {
        margin-bottom: 0;
      }

      button {
        width: max-content;
      }
    }
  }
}
