.a-text-icon {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &__text {
    padding-left: 0.5rem;
  }
}

@include rb-core-tablet {
  .a-text-icon {
    justify-content: end;

    &__text {
      padding-left: 0.25rem;
    }
  }
}
