.o-header {
  max-width: 100%;

  &__content {
    @include mobile-grid;

    margin-top: 6rem;
  }

  .a-progress-indicator-container,
  &__textIcons {
    grid-column: span 4;
  }

  &__textIcons {
    display: grid;
    grid-column: span 4;
    grid-template-columns: repeat(3, 1fr);
    height: 3rem;
  }
}

@include rb-core-tablet {
  .o-header {
    &__content {
      @include tablet-grid;
    }

    &__textIcons,
    .a-progress-indicator-container {
      gap: 2rem;
      grid-column: span 6;
    }

    .a-text-icon {
      justify-content: start;
    }
  }
}
